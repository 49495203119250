import scriptUrl from "@helb/script-url";
import config from "./config";
import log from "./log";
import { browserLanguage, isLanguageSupported } from "./language";

export default class CookieBar {
  constructor(params) {
    const validatedParams = {};

    if (typeof params === "object") {
      if (typeof params.language !== "undefined" && isLanguageSupported(params.language, config)) {
        validatedParams.language = params.language;
      }

      if (typeof params.automount === "boolean") {
        validatedParams.automount = params.automount;
      }

      if (typeof params.strings === "object") {
        validatedParams.strings = {
          ...config.languages[config.fallbackLanguage],
          ...params.strings,
        };
      }

      if (typeof params.position !== "undefined" && ["top", "bottom"].includes(params.position)) {
        validatedParams.position = params.position;
      }

      if (typeof params.changeYoutubeUrls === "boolean") {
        validatedParams.changeYoutubeUrls = params.changeYoutubeUrls;
      }

      if (typeof params.disableMatomoCookies === "boolean") {
        validatedParams.disableMatomoCookies = params.disableMatomoCookies;
      }

      if (typeof params.disableGtagCookies === "boolean") {
        validatedParams.disableGtagCookies = params.disableGtagCookies;
      }

      if (["undefined", "boolean"].includes(typeof params.defaultConsent)) {
        validatedParams.defaultConsent = params.defaultConsent;
      }

      if (typeof params.removeCookies === "boolean") {
        validatedParams.removeCookies = params.removeCookies;
      }

      if (typeof params.consentCookieExpire === "number") {
        validatedParams.consentCookieExpire = parseInt(params.consentCookieExpire);
      }

      if (Array.isArray(params.preservedCookieNames)) {
        validatedParams.preservedCookieNames = [...params.preservedCookieNames.filter((item) => typeof(item) === 'string'), `${config.baseClass}-consent`];
        validatedParams.preservedCookiePatterns = [...params.preservedCookieNames.filter((item) => typeof(item) !== 'string')];
      }
    }

    const defaultLanguage = browserLanguage(config);

    const defaults = {
      language: defaultLanguage,
      automount: true,
      position: "bottom",
      moreInfoUrl: config.moreInfoUrl,
      changeYoutubeUrls: config.changeYoutubeUrls,
      disableMatomoCookies: config.disableMatomoCookies,
      disableGtagCookies: config.disableGtagCookies,
      defaultConsent: config.defaultConsent,
      removeCookies: config.removeCookies,
      consentCookieExpire: config.consentCookieExpire,
      preservedCookieNames: config.preservedCookieNames,
      preservedCookiePatterns: config.preservedCookiePatterns,
    };

    this.options = { ...defaults, ...validatedParams };

    if (!Object.keys(this.options).includes("strings")) {
      this.options.strings = config.languages[this.options.language];
    }

    this.removedCookies = [];

    this.rootElement = document.createElement("div");
    this.rootElement.setAttribute("role", "complementary");
    this.rootElement.id = config.baseClass;
    this.rootElement.className = `${config.baseClass}-root`;
    this.rootElement.classList.add(`${config.baseClass}-root-position-${this.options.position}`);

    let consentCookie = this.getConsentCookie();

    if (consentCookie === undefined) {
      consentCookie = this.options.defaultConsent;
    }

    if (consentCookie === undefined) {
      if (this.options.automount) {
        if (document.body === null) {
          document.addEventListener("DOMContentLoaded", () => this.mount());
        } else {
          this.mount();
        }
      }
    }

    if (consentCookie !== true) {
      if (this.options.changeYoutubeUrls || this.options.disableMatomoCookies) {
        this.observer = new MutationObserver((mutation) => {
          mutation.forEach((mutationRecord) => {
            mutationRecord.addedNodes.forEach((addedNode) => {
              if (this.options.changeYoutubeUrls && addedNode.tagName === "IFRAME") {
                this.#changeYoutubeUrl(addedNode);
              }

              const matomoPattern = /_paq.push\(\[['"]trackPageView['"]\]\)/;
              if (
                this.options.disableMatomoCookies &&
                addedNode.tagName === "SCRIPT" &&
                addedNode.text.match(matomoPattern)
              ) {
                addedNode.text = addedNode.text.replace(
                  matomoPattern,
                  "_paq.push(['disableCookies']); _paq.push(['trackPageView'])"
                );
              }

              const gtagPattern = /gtag\(['"]js['"], new Date\(\)\)/;

              if (
                this.options.disableGtagCookies &&
                addedNode.tagName === "SCRIPT" &&
                addedNode.text.match(gtagPattern)
              ) {
                addedNode.text = addedNode.text.replace(
                  gtagPattern,
                  "gtag('consent','default',{'ad_storage':'denied','analytics_storage':'denied','ads_data_redaction':'true'});gtag('js',new Date())"
                );
              }
            });
          });
        });

        this.observer.observe(document, {
          attributes: true,
          childList: true,
          subtree: true,
        });

        window.addEventListener("load", () => {
          this.observer.disconnect();
        });
      }

      if (this.options.removeCookies) {
        window.addEventListener("load", () => {
          this.#removeCookies();
        });
      }
    }
  }

  getConsentCookie() {
    const consentCookie = document.cookie
      .split(";")
      .filter((kv) => kv.match(new RegExp(`^${config.baseClass}-consent=`)))
      .map((kv) => kv.replace(/.*=/, ""));

    if (consentCookie.length < 1) {
      return undefined;
    }

    return consentCookie[0] === "true";
  }

  #setConsentCookie(value) {
    const day = 24 * 60 * 60 * 1000;
    const expires = new Date(Date.now() + day * this.options.consentCookieExpire);
    document.cookie = `${config.baseClass}-consent=${value.toString()}; Expires=${expires.toUTCString()}; Secure`;
  }

  mount() {
    if (document.body.contains(this.rootElement)) {
      return;
    }

    this.rootElement.innerHTML = "";
    this.#render(this.options.language);

    // Example: <script src="/js/cookiebar.js" id="cznic-cookiebar-src" data-css="/css/cookiebar.css"></script>
    const nodeScript = document.getElementById("cznic-cookiebar-src")
    const cssUrl = nodeScript && nodeScript.dataset.css ? nodeScript.dataset.css : scriptUrl().replace(/\/[^/]+\.js$/, "/cookiebar.css");
    const styleLink = document.createElement("link");
    styleLink.id = "cookiebar";
    styleLink.rel = "stylesheet";
    styleLink.href = cssUrl;
    styleLink.addEventListener("error", () => log(`Failed to load stylesheet from '${cssUrl}'.`));
    styleLink.addEventListener("load", () => {
      if (this.options.position === "top") {
        document.body.insertBefore(this.rootElement, document.querySelector("body > *"));
      } else if (this.options.position === "bottom") {
        document.body.appendChild(this.rootElement);
      }
    });
    document.head.appendChild(styleLink);
  }

  unmount() {
    this.#removeCss();
    this.rootElement.remove();
  }

  #removeCss() {
    const styleLink = document.head.querySelector("link#cookiebar");
    document.head.removeChild(styleLink);
  }

  #changeYoutubeUrl(iframe) {
    iframe.src = iframe.src.replace("youtube.com/embed", "youtube-nocookie.com/embed");
    iframe.setAttribute("sandbox", "allow-scripts");
  }

  #matchCookieName(cookieName) {
    if (!cookieName.length) {
      return false
    }
    const result = this.options.preservedCookieNames.includes(cookieName)
    if (result) {
      return result
    }
    for (let i=0; i < this.options.preservedCookiePatterns.length; i++) {
      if (this.options.preservedCookiePatterns[i].test(cookieName)) {
        return true
      }
    }
    return false
  }

  #removeCookies() {
    document.cookie
      .split(";")
      .map((kv) => kv.replace(/=.*/, ""))
      .filter((cookieName) => !this.#matchCookieName(cookieName.trim()))
      .forEach((cookieName) => {
        document.cookie = `${cookieName}=; Max-Age=-1;`;
        this.removedCookies.push(cookieName.trim());
      });
  }

  switchLanguage(newLangCode) {
    if (this.options.language !== newLangCode) {
      if (!isLanguageSupported(newLangCode, config)) {
        log(
          `Unsupported language "${newLangCode}", ` + `switching to fallback language "${config.fallbackLanguage}".`,
          "warning"
        );
        this.switchLanguage(config.fallbackLanguage);
      } else {
        this.rootElement.innerHTML = "";
        this.options.language = newLangCode;
        this.options.strings = config.languages[newLangCode];
        this.#render();
      }
    }
  }

  switchPosition(newPosition) {
    if (["top", "bottom"].includes(newPosition) && newPosition !== this.options.position) {
      this.rootElement.classList.remove(
        `${config.baseClass}-root-position-top`,
        `${config.baseClass}-root-position-bottom`
      );
      this.options.position = newPosition;
      this.rootElement.classList.add(`${config.baseClass}-root-position-${newPosition}`);
    }
  }

  reopen() {
    this.mount();
  }

  #actionYes() {
    this.unmount();
    this.#setConsentCookie(true);
  }

  #actionNo() {
    this.#setConsentCookie(false);
    this.#removeCookies();
    Array.from(document.querySelectorAll("iframe[src*='youtube.com/embed']")).forEach((iframe) =>
      this.#changeYoutubeUrl(iframe)
    );
    this.unmount();
  }

  #render() {
    if (this.options.position === "top") {
      const jumpLink = document.createElement("a");
      jumpLink.id = `${config.baseClass}-jump`;
      jumpLink.className = `${config.baseClass}-jump`;
      jumpLink.setAttribute("aria-controls", this.rootElement.id);
      jumpLink.innerText = this.options.strings.jump;
      jumpLink.tabIndex = -1;
      this.rootElement.appendChild(jumpLink);
    }

    const textContainer = document.createElement("div");
    textContainer.innerText = this.options.strings.text;
    textContainer.className = `${config.baseClass}-text`;
    this.rootElement.appendChild(textContainer);

    const buttonContainer = document.createElement("div");
    buttonContainer.className = `${config.baseClass}-buttons`;

    const buttonYes = document.createElement("button");
    buttonYes.className = `${config.baseClass}-button ${config.baseClass}-button-yes`;
    buttonYes.innerText = this.options.strings.buttonYes;
    buttonYes.addEventListener("click", this.#actionYes.bind(this));
    buttonContainer.appendChild(buttonYes);

    const buttonNo = document.createElement("button");
    buttonNo.className = `${config.baseClass}-button ${config.baseClass}-button-no`;
    buttonNo.innerText = this.options.strings.buttonNo;
    buttonNo.addEventListener("click", this.#actionNo.bind(this));
    buttonContainer.appendChild(buttonNo);

    const linkMore = document.createElement("a");
    linkMore.className = `${config.baseClass}-link-more`;
    linkMore.target = "_blank";
    linkMore.innerText = this.options.strings.linkMore;
    linkMore.href = this.options.moreInfoUrl;
    linkMore.title = this.options.strings.newTab;
    buttonContainer.appendChild(linkMore);

    this.rootElement.appendChild(buttonContainer);
  }
}
